import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/owl.carousel.min.css";
import "./assets/css/owl.theme.default.min.css";
import Login from "./components/Auth/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import Configuration from "./components/Configuration/Configuration";
import ControlRisk from "./components/Control-Risk/ControlRisk";
import InherentRisk from "./components/Inherent-Risk/InherentRisk";
import InitiateIFCRA from "./components/Initiate-IFCRA/InitiateIFCRA";
import ResidualRisk from "./components/Residual-Risk/ResidualRisk";
import { useAuth } from "./components/Auth/AuthContext";
import PageNotFound from "../src/components/PageNotFound/PageNotFound";
import SuperAndCompAdminAuth from "../src/components/Auth/SuperAndCompAdminAuth";

const App = () => {
  const { user } = useAuth();
  useEffect(() => {
    console.clear();
  }, []);

  return (
    <Router>
      {!user?.username ? (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Login />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />

          <Route
            path="/configuration"
            element={<SuperAndCompAdminAuth component={Configuration} />}
          />
          <Route
            path="/initiate-IFCRA"
            element={<SuperAndCompAdminAuth component={InitiateIFCRA} />}
          />

          <Route path="/inherent-risk" element={<InherentRisk />} />
          <Route path="/control-risk" element={<ControlRisk />} />
          <Route path="/residual-risk" element={<ResidualRisk />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      )}
    </Router>
  );
};

export default App;
