import axios from 'axios';
import environment from "../environments/environment";

// Create a new Axios instance with custom configuration
const Instance = axios.create({
    baseURL: environment.apiUrl,
    timeout: (1000 * 60 * 5),
    headers: {
        'Content-Type': 'application/json',
        // Add any common headers (e.g., authentication tokens) here
    },
});

export default Instance;
