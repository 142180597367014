export const isPercentageValid = (data) => {
    const overrideStatuses = ['Pending', 'Approved'];
    let isAllValid = true; // Initialize a flag variable as true

    data.forEach((riskType) => {
        let unitTotal = 0;
        riskType.assessment_units.forEach((unit) => {
            unitTotal += Number(overrideStatuses.includes(unit.status) ? unit.overrideWeight : unit.weight);

            let categoriesTotal = 0;
            unit.risk_categories.forEach((category) => {
                categoriesTotal += Number(overrideStatuses.includes(category.status) ? category.overrideWeight : category.weight);

                let subCatTotal = 0;
                category.sub_categories.forEach((sc) => {
                    subCatTotal += Number(overrideStatuses.includes(sc.status) ? sc.overrideWeight : sc.weight);

                    let kriTotal = 0;
                    sc.key_risk_indicators.forEach((kri) => {
                        kriTotal += Number(overrideStatuses.includes(kri.status) ? kri.overrideWeight : kri.weight);
                    });
                    if(kriTotal !== 100 && sc.key_risk_indicators.length > 0){
                        console.log(`Total kri under subCat percent in ${riskType.name} > ${unit.name} > ${category.name} > ${sc.name}: `, kriTotal);
                        isAllValid = false;
                    }
                });
                if(subCatTotal !== 100 && category.sub_categories.length > 0){
                    console.log(`Total subCat percent in ${riskType.name} > ${unit.name} > ${category.name}:`, subCatTotal);
                    isAllValid = false;
                }

                let kriTotal = 0;
                category.key_risk_indicators.forEach((kri) => {
                    kriTotal += Number(overrideStatuses.includes(kri.status) ? kri.overrideWeight : kri.weight);
                });
                if(kriTotal !== 100 && category.key_risk_indicators.length > 0){
                    console.log(`Total kri percent in ${riskType.name} > ${unit.name} > ${category.name}: `, kriTotal);
                    isAllValid = false;
                }
            });

            if(categoriesTotal !== 100){
                console.log(`Total categories percent in ${riskType.name} > ${unit.name}: `, categoriesTotal);
                isAllValid = false;
            }
        });

        if(unitTotal !== 100){
            console.log(`Total Unit percent in ${riskType.name}: `, unitTotal);
            isAllValid = false;
        }
    });

    return isAllValid; // Return the flag variable
};
