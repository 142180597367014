import React, { useRef, useState } from "react";
import { default as infoIcon } from "../../assets/image/i-icon.svg";
import DownloadAsPDF from "../../pages/DownloadAsPDF";
import EmailPopup from "../../pages/Email/EmailPopup";
import InforModal from "../../pages/InformationModal/InfoModal";
import { showNotification } from "../../utils/customHooks/Toast";
import { useAssessmentunit } from "../Auth/ActiveAssessmentContext";
import { useAuth } from "../Auth/AuthContext";
import Layout from "../Common/Layout";
import "./ResidualRisk.css";

const blocks = [
  "Consumer Banking - Risk Assessment",
  "Wholesale Banking - Risk Assessment",
  "Business Banking - Risk Assessment",
  "Bank Wide - Overall Risk Assessment",
];

const blocksUser1 = [
  "Consumer Banking - Risk Assessment",
  "Bank Wide Risk Assessment",
];
const blocksUser2 = ["System Generated", "Revised Risk Assessment"];

const ResidualRisk = () => {
  const { user } = useAuth();
  const [showInformationModal, setShowInformationModal] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);


  // Ref for a DOM element
  const contentRef = useRef(null);
  const { selectedUnit } = useAssessmentunit();

  const handleShowInformationModal = () => {
    setShowInformationModal(true);
  };
  const handleCloseInformationModal = () => setShowInformationModal(false);


  const handleApprove = () => {
    setIsLoading1(true);
    setTimeout(() => {
      showNotification("Request Approved", "success");
      setIsLoading1(false);
      setIsApproved(true);
    }, 1500);
  };

  const handleReject = () => {
    setIsLoading2(true);
    setTimeout(() => {
      showNotification("Request Rejected", "success");
      setIsLoading2(false);
      setIsRejected(true);
    }, 1500);
  };

  const handleSubmit = () => {
    setIsLoading1(true);
    setTimeout(() => {
      showNotification("Submitted Successfully", "success");
      setIsLoading1(false);
      setIsApproved(true);
    }, 1500);
  };
  const handleReset = () => {
    setIsLoading2(true);
    setTimeout(() => {
      showNotification("Reset Successfully", "success");
      setIsLoading2(false);
      setIsApproved(true);
    }, 1500);
  };
  

  return (
    <Layout>
      <div className="total-overview" ref={contentRef}>
        <div className="top-heading">
          <div>
            <h2 className="heading-common exclude-from-pdf">
              Residual Risk{" "}
              {user.role === "compliance-user" && `(${selectedUnit})`}
            </h2>
          </div>

          <div className="button-configuration d-flex justify-content-between">
            <div>
              {/* {user.role === "business-user" ? (
                <>
                  <button className="reset-btn">
                    <i className="fa fa-times-circle-o mx-1"></i>
                    Reset
                  </button>
                  <button className="save-btn m-30">
                    <i className="fa fa-check-circle-o mx-1"></i>
                    Submit
                  </button>
                </>
              ) : (
                <>
                  <button className="reset-btn">
                    <i className="fa fa-times-circle-o mx-1"></i>
                    Reject
                  </button>
                  <button className="save-btn m-30">
                    <i className="fa fa-check-circle-o mx-1"></i>
                    Approve
                  </button>
                </>
              )} */}

              {user.role !== "business-user" ? (
                <>
                  {(selectedUnit === "Consumer banking" ||
                    selectedUnit === "Wholesale banking" ||
                    selectedUnit === "Business banking") && (
                    <>
                      <button
                        className={`reset-btn ${
                          isRejected || isApproved
                            ? "disabled disabled-button"
                            : ""
                        }`}
                        onClick={handleReject}
                        disabled={isRejected || isApproved}
                      >
                        {!isLoading2 ? (
                          <React.Fragment>
                            <i className="fa fa-times-circle-o mx-1"></i>
                            Reject
                          </React.Fragment>
                        ) : (
                          <i
                            className="fa fa-spinner fa-spin"
                            style={{ fontSize: 24 }}
                          />
                        )}
                      </button>
                      <button
                        className={`save-btn m-30 ${
                          isApproved || isRejected
                            ? "disabled disabled-button"
                            : ""
                        }`}
                        onClick={handleApprove}
                        disabled={isRejected || isApproved}
                      >
                        {!isLoading1 ? (
                          <React.Fragment>
                            <i className="fa fa-check-circle-o mx-1"></i>
                            Approve
                          </React.Fragment>
                        ) : (
                          <i
                            className="fa fa-spinner fa-spin"
                            style={{ fontSize: 24 }}
                          />
                        )}
                      </button>
                    </>
                  )}
                </>
              ) : (
                <>
                  <button
                    className={`reset-btn ${
                      isRejected || isApproved ? "disabled disabled-button" : ""
                    }`}
                    onClick={handleReset}
                    disabled={isRejected || isApproved}
                  >
                    {!isLoading2 ? (
                      <React.Fragment>
                        <i className="fa fa-times-circle-o mx-1"></i>
                        Reset
                      </React.Fragment>
                    ) : (
                      <i
                        className="fa fa-spinner fa-spin"
                        style={{ fontSize: 24 }}
                      />
                    )}
                  </button>
                  <button
                    className={`save-btn m-30 ${
                      isApproved || isRejected ? "disabled disabled-button" : ""
                    }`}
                    onClick={handleSubmit}
                    disabled={isRejected || isApproved}
                  >
                    {!isLoading1 ? (
                      <React.Fragment>
                        <i className="fa fa-check-circle-o mx-1"></i>
                        Submit
                      </React.Fragment>
                    ) : (
                      <i
                        className="fa fa-spinner fa-spin"
                        style={{ fontSize: 24 }}
                      />
                    )}
                  </button>
                </>
              )}
            </div>

            <div
              className="d-flex justify-content-center align-items-center p-0 top-icons-heading"
              style={{ marginRight: "25px" }}
            >
              <img
                src={infoIcon}
                className="exclude-from-pdf"
                alt="icon"
                onClick={handleShowInformationModal}
                style={{ cursor: "pointer" }}
              />
              <DownloadAsPDF contentRef={contentRef} />
              <EmailPopup contentRef={contentRef} />
            </div>
          </div>
        </div>

        {/* breadcrumb */}
        <div className="col-12 exclude-from-pdf">
          {/* <AssessmentLogs data={null} /> */}
        </div>

        {user.role === "compliance-user" ? (
          <div className="row common-tab scroll-cover-residual">
            {selectedUnit === "All" &&
              blocks.map((item, index) => (
                <div className="col-md-6" key={index}>
                  <div className="residual-box">
                    <label>{item}</label>
                    <table className="table">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Inherent Risk</th>
                          <th>Control Risk</th>
                          <th>Residual Risk</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>Customer Risk</th>
                          <td>
                            <div className="custom-cube danger"></div>
                          </td>
                          <td>
                            <div className="custom-cube warning"></div>
                          </td>
                          <td>
                            <div className="custom-cube danger"></div>
                          </td>
                        </tr>
                        <tr>
                          <th>Geography Risk</th>
                          <td>
                            <div className="custom-cube danger"></div>
                          </td>
                          <td>
                            <div className="custom-cube success"></div>
                          </td>
                          <td>
                            <div className="custom-cube warning"></div>
                          </td>
                        </tr>
                        <tr>
                          <th>Product Services</th>
                          <td>
                            <div className="custom-cube warning"></div>
                          </td>
                          <td>
                            <div className="custom-cube danger"></div>
                          </td>
                          <td>
                            <div className="custom-cube warning"></div>
                          </td>
                        </tr>
                        <tr>
                          <th>Channel Risk</th>
                          <td>
                            <div className="custom-cube success"></div>
                          </td>
                          <td>
                            <div className="custom-cube warning"></div>
                          </td>
                          <td>
                            <div className="custom-cube warning"></div>
                          </td>
                        </tr>
                        <tr>
                          <th>TF/PF Risk</th>
                          <td>
                            <div className="custom-cube danger"></div>
                          </td>
                          <td>
                            <div className="custom-cube warning"></div>
                          </td>
                          <td>
                            <div className="custom-cube danger"></div>
                          </td>
                        </tr>
                        <tr>
                          <th>Fraud Risk</th>
                          <td>
                            <div className="custom-cube danger"></div>
                          </td>
                          <td>
                            <div className="custom-cube warning"></div>
                          </td>
                          <td>
                            <div className="custom-cube danger"></div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            {selectedUnit === "Consumer banking" &&
              (() => {
                const blocksToShow = blocks.filter(
                  (item) =>
                    item === "Consumer Banking - Risk Assessment" ||
                    item === "Bank Wide - Overall Risk Assessment"
                );
                return blocksToShow.map((item, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="residual-box">
                      <label>{item}</label>
                      <table className="table">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Inherent Risk</th>
                            <th>Control Risk</th>
                            <th>Residual Risk</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Customer Risk</th>
                            <td>
                              <div className="custom-cube danger"></div>
                            </td>
                            <td>
                              <div className="custom-cube warning"></div>
                            </td>
                            <td>
                              <div className="custom-cube danger"></div>
                            </td>
                          </tr>
                          <tr>
                            <th>Geography Risk</th>
                            <td>
                              <div className="custom-cube danger"></div>
                            </td>
                            <td>
                              <div className="custom-cube success"></div>
                            </td>
                            <td>
                              <div className="custom-cube warning"></div>
                            </td>
                          </tr>
                          <tr>
                            <th>Product Services</th>
                            <td>
                              <div className="custom-cube warning"></div>
                            </td>
                            <td>
                              <div className="custom-cube danger"></div>
                            </td>
                            <td>
                              <div className="custom-cube warning"></div>
                            </td>
                          </tr>
                          <tr>
                            <th>Channel Risk</th>
                            <td>
                              <div className="custom-cube success"></div>
                            </td>
                            <td>
                              <div className="custom-cube warning"></div>
                            </td>
                            <td>
                              <div className="custom-cube warning"></div>
                            </td>
                          </tr>
                          <tr>
                            <th>TF/PF Risk</th>
                            <td>
                              <div className="custom-cube danger"></div>
                            </td>
                            <td>
                              <div className="custom-cube warning"></div>
                            </td>
                            <td>
                              <div className="custom-cube danger"></div>
                            </td>
                          </tr>
                          <tr>
                            <th>Fraud Risk</th>
                            <td>
                              <div className="custom-cube danger"></div>
                            </td>
                            <td>
                              <div className="custom-cube warning"></div>
                            </td>
                            <td>
                              <div className="custom-cube danger"></div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                ));
              })()}
            {selectedUnit === "Wholesale banking" &&
              (() => {
                const blocksToShow = blocks.filter(
                  (item) =>
                    item === "Wholesale Banking - Risk Assessment" ||
                    item === "Bank Wide - Overall Risk Assessment"
                );
                return blocksToShow.map((item, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="residual-box">
                      <label>{item}</label>
                      <table className="table">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Inherent Risk</th>
                            <th>Control Risk</th>
                            <th>Residual Risk</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Customer Risk</th>
                            <td>
                              <div className="custom-cube danger"></div>
                            </td>
                            <td>
                              <div className="custom-cube warning"></div>
                            </td>
                            <td>
                              <div className="custom-cube danger"></div>
                            </td>
                          </tr>
                          <tr>
                            <th>Geography Risk</th>
                            <td>
                              <div className="custom-cube danger"></div>
                            </td>
                            <td>
                              <div className="custom-cube success"></div>
                            </td>
                            <td>
                              <div className="custom-cube warning"></div>
                            </td>
                          </tr>
                          <tr>
                            <th>Product Services</th>
                            <td>
                              <div className="custom-cube warning"></div>
                            </td>
                            <td>
                              <div className="custom-cube danger"></div>
                            </td>
                            <td>
                              <div className="custom-cube warning"></div>
                            </td>
                          </tr>
                          <tr>
                            <th>Channel Risk</th>
                            <td>
                              <div className="custom-cube success"></div>
                            </td>
                            <td>
                              <div className="custom-cube warning"></div>
                            </td>
                            <td>
                              <div className="custom-cube warning"></div>
                            </td>
                          </tr>
                          <tr>
                            <th>TF/PF Risk</th>
                            <td>
                              <div className="custom-cube danger"></div>
                            </td>
                            <td>
                              <div className="custom-cube warning"></div>
                            </td>
                            <td>
                              <div className="custom-cube danger"></div>
                            </td>
                          </tr>
                          <tr>
                            <th>Fraud Risk</th>
                            <td>
                              <div className="custom-cube danger"></div>
                            </td>
                            <td>
                              <div className="custom-cube warning"></div>
                            </td>
                            <td>
                              <div className="custom-cube danger"></div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                ));
              })()}
            {selectedUnit === "Business banking" &&
              (() => {
                const blocksToShow = blocks.filter(
                  (item) =>
                    item === "Business Banking - Risk Assessment" ||
                    item === "Bank Wide - Overall Risk Assessment"
                );
                return blocksToShow.map((item, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="residual-box">
                      <label>{item}</label>
                      <table className="table">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Inherent Risk</th>
                            <th>Control Risk</th>
                            <th>Residual Risk</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Customer Risk</th>
                            <td>
                              <div className="custom-cube danger"></div>
                            </td>
                            <td>
                              <div className="custom-cube warning"></div>
                            </td>
                            <td>
                              <div className="custom-cube danger"></div>
                            </td>
                          </tr>
                          <tr>
                            <th>Geography Risk</th>
                            <td>
                              <div className="custom-cube danger"></div>
                            </td>
                            <td>
                              <div className="custom-cube success"></div>
                            </td>
                            <td>
                              <div className="custom-cube warning"></div>
                            </td>
                          </tr>
                          <tr>
                            <th>Product Services</th>
                            <td>
                              <div className="custom-cube warning"></div>
                            </td>
                            <td>
                              <div className="custom-cube danger"></div>
                            </td>
                            <td>
                              <div className="custom-cube warning"></div>
                            </td>
                          </tr>
                          <tr>
                            <th>Channel Risk</th>
                            <td>
                              <div className="custom-cube success"></div>
                            </td>
                            <td>
                              <div className="custom-cube warning"></div>
                            </td>
                            <td>
                              <div className="custom-cube warning"></div>
                            </td>
                          </tr>
                          <tr>
                            <th>TF/PF Risk</th>
                            <td>
                              <div className="custom-cube danger"></div>
                            </td>
                            <td>
                              <div className="custom-cube warning"></div>
                            </td>
                            <td>
                              <div className="custom-cube danger"></div>
                            </td>
                          </tr>
                          <tr>
                            <th>Fraud Risk</th>
                            <td>
                              <div className="custom-cube danger"></div>
                            </td>
                            <td>
                              <div className="custom-cube warning"></div>
                            </td>
                            <td>
                              <div className="custom-cube danger"></div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                ));
              })()}
          </div>
        ) : (
          <div className="row common-tab scroll-cover-residual">
            {blocksUser1.map((item, index) => (
              <div className="col-md-6" key={index}>
                <div className="residual-box">
                  <label>{item}</label>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Inherent Risk</th>
                        <th>Control Risk</th>
                        <th>Residual Risk</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="bg-white">
                          <div className="custom-cube danger"></div>
                        </td>
                        <td>
                          <div className="custom-cube success"></div>
                        </td>
                        <td>
                          <div className="custom-cube warning"></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
            {blocksUser2.map((item, index) => (
              <div className="col-md-6" key={index}>
                <div className="residual-box">
                  <label>{item}</label>
                  <table className="table">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Inherent Risk</th>
                        <th>Control Risk</th>
                        <th>Residual Risk</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Customer Risk</th>
                        <td>
                          <div className="custom-cube danger"></div>
                        </td>
                        <td>
                          <div className="custom-cube warning"></div>
                        </td>
                        <td>
                          <div className="custom-cube danger"></div>
                        </td>
                      </tr>
                      <tr>
                        <th>Geography Risk</th>
                        <td>
                          <div className="custom-cube danger"></div>
                        </td>
                        <td>
                          <div className="custom-cube success"></div>
                        </td>
                        <td>
                          <div className="custom-cube warning"></div>
                        </td>
                      </tr>
                      <tr>
                        <th>Product Services</th>
                        <td>
                          <div className="custom-cube warning"></div>
                        </td>
                        <td>
                          <div className="custom-cube danger"></div>
                        </td>
                        <td>
                          <div className="custom-cube warning"></div>
                        </td>
                      </tr>
                      <tr>
                        <th>Channel Risk</th>
                        <td>
                          <div className="custom-cube success"></div>
                        </td>
                        <td>
                          <div className="custom-cube warning"></div>
                        </td>
                        <td>
                          <div className="custom-cube warning"></div>
                        </td>
                      </tr>
                      <tr>
                        <th>TF/PF Risk</th>
                        <td>
                          <div className="custom-cube danger"></div>
                        </td>
                        <td>
                          <div className="custom-cube warning"></div>
                        </td>
                        <td>
                          <div className="custom-cube danger"></div>
                        </td>
                      </tr>
                      <tr>
                        <th>Fraud Risk</th>
                        <td>
                          <div className="custom-cube danger"></div>
                        </td>
                        <td>
                          <div className="custom-cube danger"></div>
                        </td>
                        <td>
                          <div className="custom-cube warning"></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <InforModal
        showModal={showInformationModal}
        handleCloseModal={handleCloseInformationModal}
        path="residual-risk"
      />
    </Layout>
  );
};

export default ResidualRisk;
