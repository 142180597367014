import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { showNotification } from "../utils/customHooks/Toast";
import { getTrendChart } from "../services/ApiService";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ selectedUnit, selectedInterval }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
        align: "start",
        position: "bottom",
        rtl: false,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
      title: {
        display: false,
        text: "Trend Chart",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label;
            const value = data.datasets[0].data[context.dataIndex];
            return `${label}: ${value}%`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: true, // Hide x-axis gridlines
        },
      },
      y: {
        grid: {
          display: false, // Hide y-axis gridlines
        },
      },
    },
  };

  const [config, setConfig] = useState(null);
  const [datasets, setDatasets] = useState([]);

  //  Generate labels based on selectedInterval
  const labels = generateLabels(selectedInterval);

  const data = {
    labels,
    datasets,
  };

  useEffect(() => {
    const fetchTrendChartData = async () => {
      try {
        const response = await getTrendChart();
        // console.log("response.data", response.data);
        setConfig(response.data);
        if (response.data[selectedInterval][selectedUnit]) {
          setDatasets(response.data[selectedInterval][selectedUnit]);
        }
      } catch (error) {
        console.log("Error while fetching trend chart data : ", error);
        showNotification(
          "Something went wrong while fetching trend chart data",
          "error"
        );
      }
    };
    fetchTrendChartData();
  }, []);

  return <Line options={options} data={data} style={{ width: "200px" }} />;
};

// Function to generate labels based on the selectedInterval
const generateLabels = (selectedInterval) => {
  if (selectedInterval === "Yearly") {
    return ["2019", "2020", "2021", "2022"];
  } else if (selectedInterval === "Quarterly") {
    return ["Q1", "Q2", "Q3", "Q4"];
  }
  return [];
};

export default LineChart;
