import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BookIcon from "../../assets/image/book-icon.svg";
import { useAuth } from "../../components/Auth/AuthContext";
import { updateKeyRiskIndicator } from "../../services/ApiService";
import { showNotification } from "../../utils/customHooks/Toast";
import FileUpload from "../FileUpload";
import "./ChangeRatingModal.css";

const ChangeRatingModal = ({
  showModal,
  handleCloseModal,
  selectedKRI,
  ratingLabel,
  ratingOptions,
}) => {
  const { user } = useAuth();
  const initialState = {
    overrideRisk: selectedKRI.overrideRisk ?? selectedKRI.risk,
    remark: selectedKRI.remark,
    status: selectedKRI.status,
  };
  const [updatedKRI, setUpdatedKRI] = useState(initialState);
  const [selectedAction, setSelectedAction] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (selectedKRI) {
      const initialState = {
        overrideRisk: selectedKRI.overrideRisk ?? selectedKRI.risk,
        remark: selectedKRI.remark,
        status: selectedKRI.status,
      };
      setUpdatedKRI(initialState);
    }
  }, [selectedKRI, user.role]);

  const handleTextareaChange = (name, value) => {
    const updatedKRIData = {
      ...updatedKRI,
      [name]: value,
    };
    setUpdatedKRI(updatedKRIData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append("overrideRisk", updatedKRI.overrideRisk);
    const mergedRemark = `${
      updatedKRI.remark ? `${updatedKRI.remark.replace(/:/g, ":\n")}\n` : ""
    }${user.name}: ${updatedKRI.newRemark},`;
    formData.append("remark", mergedRemark);

    if (selectedAction === "approved") {
      formData.append("status", "Approved");
    } else if (selectedAction === "rejected") {
      formData.append("status", "Rejected");
    } else {
      formData.append("status", "Pending");
    }


    try {
      const response = await updateKeyRiskIndicator(selectedKRI.id, formData);
      handleCloseModal();
      setUpdatedKRI(initialState);
      showNotification(`Updated Successfully.`, "success");
      setIsLoading(false); // Set loading to false when the request is complete
    } catch (error) {
      showNotification(`All fields are required`, "error");
      setIsLoading(false); // Set loading to false when the request is complete
    }
  };

  const handleReset = () => {
    setUpdatedKRI(initialState); // Reset the form fields to initial values
  };

  if (!selectedKRI) {
    return null;
  }

  return (
    <>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedKRI.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="statistical-box">
              <p className="rating-btn">
                <Button
                  variant="text"
                  className={`${selectedKRI.risk} active square-btn`}
                ></Button>
                Existing Rating
              </p>
              <div className="rating-btn">
                <p>{ratingLabel}</p>
                {ratingOptions.map((text, index) => (
                  <Button
                    key={index}
                    variant="text"
                    name="overrideRisk"
                    className={`${text} ${
                      updatedKRI.overrideRisk === text ? "active" : ""
                    }`}
                    onClick={() => handleTextareaChange("overrideRisk", text)}
                  >
                    {text}
                  </Button>
                ))}
              </div>
            </div>
            <div>
              {updatedKRI.remark && (
                <>
                  <h7>Previous Remarks:</h7>
                  <p>
                    {updatedKRI.remark.split(",").map((item, index) => (
                      <React.Fragment key={index}>
                        {item}
                        {index !== updatedKRI.remark.split(",").length - 1 && (
                          <br />
                        )}
                      </React.Fragment>
                    ))}
                  </p>
                </>
              )}
            </div>

            <div className="Write-Justification">
              <div className="inner-body">
                <h3>
                  <img src={BookIcon} alt="icon" /> Write your Justification
                </h3>
                <textarea
                  value={updatedKRI.newRemark}
                  name="newRemark"
                  cols="96"
                  rows="5"
                  required
                  maxLength={300}
                  onChange={(e) =>
                    handleTextareaChange("newRemark", e.target.value)
                  }
                  placeholder="Enter your justification here..."
                />
              </div>
            </div>
            <div className="popup-uplod-btn">
              <FileUpload />
              {user.role !== "compliance-user" && (
                <>
                  <button type="button" onClick={handleReset}>
                    Reset
                  </button>
                  <button
                    type="submit"
                    className={`save-btn-pop ${updatedKRI.status === "Approved" ? "disabled disabled-button" : ""}`}
                    onClick={() => setSelectedAction("Pending")}
                    disabled={
                      isLoading || (updatedKRI.status === "Approved")
                    }
                  >
                    {!isLoading ? (
                      "Save"
                    ) : (
                      <i
                        className="fa fa-spinner fa-spin"
                        style={{ fontSize: 24 }}
                      />
                    )}
                  </button>
                </>
              )}
              {user.role === "compliance-user" && (
                <>
                  <button
                    className={`reset-btn text-white ${
                      (updatedKRI.status !== "Pending" &&
                        updatedKRI.status !== "Approved" &&
                        updatedKRI.status !== "Rejected") ? "disabled disabled-button" : " "}`}
                    onClick={() => setSelectedAction("rejected")}
                    disabled={
                      updatedKRI.status !== "Pending" &&
                      updatedKRI.status !== "Approved" &&
                      updatedKRI.status !== "Rejected"
                    }
                  >
                    Reject
                  </button>

                  <button
                    type="submit"
                    className={`save-btn-pop ${
                      (updatedKRI.status !== "Pending" &&
                        updatedKRI.status !== "Approved" &&
                        updatedKRI.status !== "Rejected") ? "disabled disabled-button" : " "}`}
                    onClick={() => setSelectedAction("approved")}
                    disabled={
                      isLoading ||
                      (updatedKRI.status !== "Pending" &&
                        updatedKRI.status !== "Approved" &&
                        updatedKRI.status !== "Rejected")
                    }
                  >
                    {!isLoading ? (
                      "Approve"
                    ) : (
                      <i
                        className="fa fa-spinner fa-spin"
                        style={{ fontSize: 24 }}
                      />
                    )}
                  </button>
                </>
              )}
              {/* {user.role === "compliance-user" && (
                <button
                  className="reset-btn text-white"
                  onClick={() =>
                    setUpdatedKRI({ ...updatedKRI, status: "Rejected" })
                  }
                >
                  Reject
                </button>
              )}
              <button type="submit" className="save-btn-pop">
                {user.role !== "compliance-user" ? "Save" : "Approve"}
              </button> */}
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default ChangeRatingModal;
