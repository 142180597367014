import React, { createContext, useContext, useMemo, useState } from "react";

// Create a context for the app
const AppContext = createContext();

// Create a provider component
export const AppProvider = ({ children }) => {
    // Initialize the user state
    const [user, setUser] = useState(() => {
        // Check if a user is saved in local storage
        const savedUser = localStorage.getItem('user');
        return savedUser ? JSON.parse(savedUser) : null;
    });
    
    // Wrap the context value in useMemo to memoize it
    const contextValue = useMemo(() => ({
        user,
        setUser,
        logout: () => {
            // Clear user data from state
            setUser(null);
            // Clear user data from local storage
            localStorage.removeItem('user');
        },
    }), [user]);

    return (
        // Provide the context value to the components
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    );
}

// Create a custom hook for using the auth context
export const useAuth = () => {
    return useContext(AppContext);
};

