import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import downloadIcon from '../assets/image/download-icon.svg';

const DownloadAsPDF = ({ contentRef }) => {

  const handleDownloadPDF = () => {
    const divToCapture = contentRef.current;

    // Create a list of elements to exclude from the PDF
    const elementsToExclude = divToCapture.querySelectorAll('.exclude-from-pdf');

    // Hide the elements to exclude
    elementsToExclude.forEach((element) => {
      element.style.display = 'none';
    });

    html2canvas(divToCapture).then((canvas) => {
      // Restore the visibility of excluded elements
      elementsToExclude.forEach((element) => {
        element.style.display = '';
      });

      const imgData = canvas.toDataURL('image/png');

      const pdf = new jsPDF('l', 'mm', 'legal'); // 'l' stands for landscape

      // Define the maximum height for the image within the PDF
      const maxHeight = pdf.internal.pageSize.height - 20; // Subtracting 20 for some margin

      const img = new Image();
      img.src = imgData;

      img.onload = function () {
        const originalWidth = img.width;
        const originalHeight = img.height;

        // Calculate the scaling factor to fit within the defined height
        const scaleFactor = maxHeight / originalHeight;
        const scaledWidth = originalWidth * scaleFactor;
        const scaledHeight = originalHeight * scaleFactor;

        // Calculate the X coordinate to center the image
        const xCoordinate = (pdf.internal.pageSize.width - scaledWidth) / 2;

        pdf.addImage(imgData, 'PNG', xCoordinate, 10, scaledWidth, scaledHeight);
        pdf.save('iFCRA-Report.pdf');
      };
    });
  };


  return (
    <img src={downloadIcon}
      className="exclude-from-pdf"
      alt="icon"
      onClick={handleDownloadPDF}
      style={{ cursor: 'pointer' }}
    />
  );
};

export default DownloadAsPDF;
