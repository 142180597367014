import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import mailIcon from "../../assets/image/mail.svg";
import { uploadImage } from "../../services/ApiService";
import { useAssessmentunit } from "../../components/Auth/ActiveAssessmentContext";
import "./EmailPopup.css";
const EmailPopup = ({ contentRef }) => {
  const [loading, setLoading] = useState(false); // Initialize the loading state
  const location = useLocation();
  const { selectedUnit } = useAssessmentunit();

  const handleEmailSubmit = () => {
    setLoading(true); // Set loading state to true

    const divToCapture = contentRef.current;

    // Create a list of elements to exclude from the PDF
    const elementsToExclude =
      divToCapture.querySelectorAll(".exclude-from-pdf");

    // Hide the elements to exclude
    elementsToExclude.forEach((element) => {
      element.style.display = "none";
    });

    html2canvas(divToCapture).then((canvas) => {
      // Restore the visibility of excluded elements
      elementsToExclude.forEach((element) => {
        element.style.display = "";
      });

      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("l", "mm", "legal"); // 'l' stands for landscape

      // Define the maximum height for the image within the PDF
      const maxHeight = pdf.internal.pageSize.height - 20; // Subtracting 20 for some margin

      const img = new Image();
      img.src = imgData;

      img.onload = function () {
        const originalWidth = img.width;
        const originalHeight = img.height;

        // Calculate the scaling factor to fit within the defined height
        const scaleFactor = maxHeight / originalHeight;
        const scaledWidth = originalWidth * scaleFactor;
        const scaledHeight = originalHeight * scaleFactor;

        // Calculate the X coordinate to center the image
        const xCoordinate = (pdf.internal.pageSize.width - scaledWidth) / 2;

        pdf.addImage(
          imgData,
          "PNG",
          xCoordinate,
          10,
          scaledWidth,
          scaledHeight
        );
        // Convert the image data to a Blob
        const blob = dataURItoBlob(imgData);

        // Create a FormData object and append the image as a file
        const formData = new FormData();
        formData.append("image", blob, "iFCRA-Report.png");

        uploadImage(formData)
          .then((response) => {
            setLoading(false); // Clear loading state when the response is received

            // Define the base URL for Gmail
            const baseUrl =
              "https://mail.google.com/mail/u/0/?fs=1&to=recipient@example.com&su=iFCRA-Report";

            // Get the image URL from your response data (you may need to fetch it)
            const image_url = response.data.image_url; // Replace 'response.data.image_url' with the actual data retrieval code

            const fullUrl = `${baseUrl}&body=Hi+Dear,+Click+to+see+Screen+shot+-+${image_url}&tf=cm`;
            const width = Math.min(800, window.screen.availWidth - 100);
            const height = Math.min(600, window.screen.availHeight - 100);
            const left = (window.screen.availWidth - width) / 2;
            const top = (window.screen.availHeight - height) / 2;
            const windowFeatures = `width=${width},height=${height},left=${left},top=${top},location=no,toolbar=no,scrollbars=no`;
            window.open(fullUrl, "_blank", windowFeatures);
          })
          .catch((error) => {
            setLoading(false); // Clear loading state in case of an error
            console.log(error);
          });
      };
    });
  };

  // Function to convert a data URL to a Blob
  function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }

  return (
    <button
      className="border-0 p-0"
      onClick={handleEmailSubmit}
      style={{
        width:
          location.pathname === "/residual-risk"
            ? "0px"
            : undefined,
      }}
    >
      {loading ? (
        <i className="fa fa-spinner fa-spin" style={{ fontSize: "18px" }} />
      ) : (
        <img
          src={mailIcon}
          alt="icon"
          className="exclude-from-pdf"
          style={{ cursor: "pointer" }}
        />
      )}
    </button>
  );
};

export default EmailPopup;
