import React, { useState } from 'react';
import PinIcon from "../assets/image/pin-icon.svg";

const FileUpload = () => {
    const [selectedFileName, setSelectedFileName] = useState(null);

    const handleFileUpload = (e) => {
        const selectedFile = e.target.files[0];

        // Check if a file was selected
        if (selectedFile) {
            // Process the selected file as needed
            console.log('Selected file:', selectedFile);

            // Format the filename for display, showing the first 4 characters and the extension
            const fileNameParts = selectedFile.name.split('.');
            const fileName = `${fileNameParts[0].substring(0, 4)}...${fileNameParts[fileNameParts.length - 1]}`;

            // Set the formatted selected file name in state to display to the user
            setSelectedFileName(fileName);
        } else {
            // If no file is selected, display "No file selected"
            setSelectedFileName('No file selected');
        }
    };

    const handleFileInputClick = (event) => {
        event.preventDefault();
        const fileInput = document.getElementById('fileInput');
        if (fileInput) {
            fileInput.click();
        }
    };

    return (
        <>
            <a href='/' onClick={handleFileInputClick}>
                {!selectedFileName ? (
                    <>
                        <img src={PinIcon} alt="icon" />
                        Upload
                    </>
                ) : (
                    <span className="ellipsis">{selectedFileName}</span>
                )}
            </a>
            <input type="file" id="fileInput" onChange={handleFileUpload} />
        </>
    );
}

export default FileUpload;
