import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

// Importing images
import arrowRight from "../../assets/image/arrow-right-side.svg";
import imageArrow from "../../assets/image/arrow.svg";
import headphoneIcon from "../../assets/image/headphone.svg";
import logo from "../../assets/image/logo.svg";
import settingsIcon from "../../assets/image/setting-icon.svg";
import userLogo from "../../assets/image/userLogo.png";
import InfoTip from "../../pages/InfoTip";

// Importing context and services
import {
  getActiveAssessments,
  updateActiveAssessment,
} from "../../services/ApiService";
import {
  AssessmentUnitContext,
  useAssessmentunit,
} from "../Auth/ActiveAssessmentContext";
import { useAuth } from "../Auth/AuthContext";
import environment from "../../environments/environment";

const Navigation = () => {
  // State variables
  const [activeUnit, setActiveUnit] = useState("All");
  const [subMenu, setSubMenu] = useState(0);
  const [subMenuVisible, setSubMenuVisible] = useState(false);
  const [assessmentUnits, setAssessmentUnits] = useState([]);

  const { updateSelectedUnit } = useContext(AssessmentUnitContext);
  const { selectedUnit } = useAssessmentunit();

  // Get the current location
  const location = useLocation();

  // Authentication and navigation hooks
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  // Function to fetch assessment units
  const fetchAssessment = useCallback(() => {
    getActiveAssessments()
      .then((response) => {
        // Update the state with the fetched data
        setAssessmentUnits(response.data);
      })
      .catch((error) => {
        // console.log('Error fetching assessment units.')
      });
  }, []);

  // Effect to fetch assessment units when the component mounts
  useEffect(() => {
    fetchAssessment();
  }, [fetchAssessment]);

  // Function to handle user logout
  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const updateActiveAssessmentuUnit = (id) => {
    updateActiveAssessment(id)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Function to handle menu item click
  const handleMenuClick = (index, submenuName, assessmentId) => {
    console.log(assessmentId);
    if (submenuName === "All") {
      updateSelectedUnit(submenuName);
      navigate("/dashboard");
    } else {
      updateSelectedUnit(submenuName);
      updateActiveAssessmentuUnit(assessmentId);
      navigate("/dashboard");
    }
    setSubMenu(index);
  };

  return (
    <div className="flex-sidebar">
      <div className="sidebar-left">
        <span className="logo-dashboard">
          <a href="/">
            <img className="logo-border" src={logo} alt="logo" />
          </a>
        </span>
        <ul>
          {user.role === "compliance-user" ? (
            <li>
              <span
                className={`d-flex justify-content-between ${
                  location.pathname === "/dashboard" ? "active-firt" : ""
                }`}
                onClick={() => {
                  setSubMenuVisible(!subMenuVisible);
                }}
                style={{ cursor: "pointer", color: "#8a91a3" }}
              >
                <span>Overview</span>
                <img
                  className={subMenuVisible ? "arrow-rotate" : "arrow-right"}
                  src={arrowRight}
                  alt="logo"
                />
              </span>
              <ul
                className="sub-dropdown"
                style={{ display: subMenuVisible ? "block" : "none" }}
              >
                <li
                  className={selectedUnit === "All" ? "active-submenu" : ""}
                  onClick={() => handleMenuClick(0, "All")} // Call handleAllClick function
                  style={{ cursor: "pointer" }}
                >
                  Overall
                </li>
                {assessmentUnits.map((unit, index) => (
                  <li
                    key={unit.id}
                    onClick={() =>
                      handleMenuClick(index + 1, unit.name, unit.id)
                    }
                    className={
                      selectedUnit === unit.name ? "active-submenu" : ""
                    }
                  >
                    {unit.name}
                  </li>
                ))}
              </ul>
            </li>
          ) : (
            <li>
              <Link
                to="/dashboard"
                className={
                  location.pathname === "/dashboard" ? "active-firt" : ""
                }
              >
                Overview
              </Link>
            </li>
          )}

          {user.role === "compliance-admin" || user.role === "super-admin" ? (
            <React.Fragment>
              <li>
                <Link
                  to="/configuration"
                  className={
                    location.pathname === "/configuration" ? "active" : ""
                  }
                >
                  Configuration
                </Link>
              </li>
              <li>
                <Link
                  to="/initiate-iFCRA"
                  className={
                    location.pathname === "/initiate-iFCRA" ? "active" : ""
                  }
                >
                  Initiate IFCRA
                </Link>
              </li>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {user.role === "compliance-user" && (
                <>
                  {selectedUnit === "All" ? (
                    <>
                      <li>
                        <InfoTip
                          show={selectedUnit === "All"}
                          info="None of the assessment is Active"
                        >
                          <span
                            style={{ cursor: "not-allowed", color: "#8A91A3" }}
                          >
                            Inherent Risk
                          </span>
                        </InfoTip>
                      </li>

                      <li>
                        <InfoTip
                          show={selectedUnit === "All"}
                          info="None of the assessment is Active"
                        >
                          <span
                            style={{ cursor: "not-allowed", color: "#8A91A3" }}
                          >
                            Control Risk
                          </span>
                        </InfoTip>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <span
                          className={
                            location.pathname === "/inherent-risk"
                              ? "active"
                              : ""
                          }
                          onClick={() => navigate("/inherent-risk")}
                          style={{ cursor: "pointer", color: "#8A91A3" }}
                        >
                          Inherent Risk
                        </span>
                      </li>

                      <li>
                        <span
                          className={
                            location.pathname === "/control-risk"
                              ? "active"
                              : ""
                          }
                          onClick={() => navigate("/control-risk")}
                          style={{ cursor: "pointer", color: "#8A91A3" }}
                        >
                          Control Risk
                        </span>
                      </li>
                    </>
                  )}

                  <li>
                    <span
                      className={
                        location.pathname === "/residual-risk" ? "active" : ""
                      }
                      onClick={() =>
                        navigate("/residual-risk", {
                          state: activeUnit,
                        })
                      }
                      style={{ cursor: "pointer", color: "#8A91A3" }}
                    >
                      Residual Risk
                    </span>
                  </li>
                </>
              )}
              {user.role === "business-user" && (
                <>
                  <li>
                    <Link
                      to="/inherent-risk"
                      className={
                        location.pathname === "/inherent-risk" ? "active" : ""
                      }
                    >
                      Inherent Risk
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/control-risk"
                      className={
                        location.pathname === "/control-risk" ? "active" : ""
                      }
                    >
                      Control Risk
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/residual-risk"
                      className={
                        location.pathname === "/residual-risk" ? "active" : ""
                      }
                    >
                      Residual Risk
                    </Link>
                  </li>
                </>
              )}
            </React.Fragment>
          )}

          {user.role === "super-admin" && (
            <li>
              <Link to={`${environment.apiUrl}/admin`}>User Management</Link>
            </li>
          )}
        </ul>
      </div>
      <div className="user-part">
        <div className="user-section mx-3">
          <div className="top-push">
            <div className="profile-photo">
              <img src={userLogo} alt="userLogo" />
            </div>
            <p>{user.name}</p>
          </div>
          <div className="settings-icon">
            <img src={settingsIcon} alt="icon" />
            <img src={headphoneIcon} alt="icon" />
            <img src={imageArrow} alt="icon" onClick={handleLogout} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
