// ForbiddenPage.js
import React from "react";
import { Link } from "react-router-dom";
import "./ForbiddenPage.css";

const ForbiddenPage = () => {
  return (
    <div className="forbidden-page">
      <div className="forbidden-icon">
        <span role="img" aria-label="lock">
          🔒
        </span>
      </div>
      <h1>403</h1>
      <h2>Oops! Access Denied</h2>
      <p>Sorry, you don't have permission to access this page.</p>
      <Link to="/" className="back-to-home">
        Back to Homepage
      </Link>
    </div>
  );
};

export default ForbiddenPage;
