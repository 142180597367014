export const filterControlRiskIndicators = (data) => {
  const colors = ["#002645", "#002645", "#002645", "#33516A", "#708597", "#708597", "#33516A", "#708597", "#708597", "#708597"];

  // Filter the data to select 'Control Risk' items and flatten the structure.
  const controlRiskIndicators = data
    .filter((riskType) => riskType.name === 'Control Risk')
    .flatMap((controlRisk) =>
      controlRisk.assessment_units
        .flatMap((assessmentUnit) =>
          assessmentUnit.risk_categories.flatMap((riskCategory) =>
            riskCategory.sub_categories.flatMap((subCategory) => {
              // Filter indicators based on the 'id' property.
              const indicators = subCategory.key_risk_indicators.map(
                (kri) => kri );
              return indicators;
            })
          )
        )
    );

  // Sort the controlRiskIndicators by the "weight" property in descending order.
  controlRiskIndicators.sort((a, b) => b.weight - a.weight);

  // Truncate the array to keep only the top 10 elements.
  controlRiskIndicators.splice(10);

  // Convert the "weight" values to numbers
  controlRiskIndicators.forEach(item => {
    item.weight = parseFloat(item.weight); // Assuming "weight" is a string
  });

  // Now calculate the sum of the top 10 weights
  const sumOfTop10Weights = controlRiskIndicators
    .slice(0, 10)
    .reduce((sum, item) => sum + (item.weight || 0), 0);

  // Calculate the percentages as integers
  let percentages = controlRiskIndicators.map(item => ({
    name: item.name, // Assuming the property is called "name"
    percent: sumOfTop10Weights === 0 ? 0 : Math.floor((item.weight / sumOfTop10Weights) * 100)

  }));

  // Calculate the total percentage
  const totalPercentage = percentages.reduce((sum, item) => sum + item.percent, 0);

  // Adjust the last item to make the total 100%
  if (totalPercentage !== 100 && percentages.length > 0) {
    const lastItem = percentages[percentages.length - 1];
    lastItem.percent += 100 - totalPercentage;
    if (lastItem.percent < 0) {
      lastItem.percent = 0; // Ensure it's not negative
    }
  }

  //add colors
  percentages.sort((a, b) => b.weight - a.weight);
  percentages = percentages.map((item, index) => {
    return { ...item, color: colors[index] }
  });

  // Return the result with only 'name' and 'percentage' as integers
  return percentages;
};
