import React from 'react';
import Navigation from "./Navigation";

const Layout = ({children}) => {
    return (
        <div className="container-fluid">
            <div className="row" style={{height: '100vh'}}>
                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 px-0 max-width-sidebar">
                    <div className="fixed-layout">
                      <Navigation />
                    </div>
                </div>

                <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10 px-0 right-content-section">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Layout;