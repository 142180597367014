import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

const CustomDot = () => (
    <div style={{
        background: 'white',
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        margin: '10px 5px',
    }}></div>
);

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    customPaging: () => <CustomDot />,
};

const paragraphs = [
    "Understating AML Risk for better control",
    "Understating AML Risk for better control",
    "Understating AML Risk for better control",
];

const SliderComponent = () => {
    return (
        <>
            <Slider {...settings}>
                {paragraphs.map((text) => (
                    <div key={text.id} className="item">
                        <div className="login-sroll-text">
                            <p>{text}</p>
                        </div>
                    </div>
                ))}
            </Slider>
        </>
    );
}

export default SliderComponent;
