import React, {useEffect} from "react";
import ReactSpeedometer from "react-d3-speedometer";
import './Speedometer.css';

const Speedometer = ({ value }) => {
    let ringWidth = 12;
    if (window.screen.width >= 1100 && window.screen.width < 1200) {
        ringWidth = 9;
    } else if(window.screen.width >= 1200 && window.screen.width < 1300){
        ringWidth = 10;
    }else if(window.screen.width >= 1300 && window.screen.width < 1600){
        ringWidth = 10;
    }else if(window.screen.width >= 1600 && window.screen.width < 2000){
        ringWidth = 12;
    }else{
        ringWidth = 12;
    }
    useEffect(() => {
        const svgPath = document.querySelector('.pointer'); // Select the path with the desired class
        if (svgPath) {
            svgPath.setAttribute('rx', '10'); // Set the rx value
            svgPath.setAttribute('ry', '10'); // Set the ry value
        }
    }, []);

    return (
        <div className="speedo-container">
            <div className="needle-circle"></div>
            <ReactSpeedometer
                value={value}
                currentValueText={''}
                minValue={0}
                maxValue={100}
                fluidWidth={true}
                needleHeightRatio={0.6}
                ringWidth={ringWidth}
                needleColor="#4D4D4D"
                radius={10}
                segments={3}
                customSegmentLabels={[
                    {
                        text: '',
                        position: 'OUTSIDE',
                        color: '#ffffff',
                        fontSize: '10px',
                    },
                    {
                        text: '',
                        position: 'OUTSIDE',
                        color: '#ffffff',
                        fontSize: '10px',
                    },
                    {
                        text: '',
                        position: 'OUTSIDE',
                        color: '#ffffff',
                        fontSize: '10px',
                    }
                ]}
                segmentColors={["#0BA023", "#F8B200", "#DF4A11"]}
                //customSegmentStops={[0, 34, 66, 100]}
                needleTransition="easeElastic"
                needleTransitionDuration={4000}
            />
        </div>
    );
};

export default Speedometer;
