import {OverlayTrigger, Tooltip} from "react-bootstrap";

const InfoTip = ({show, info, children}) => {
    if(!show){
        return children;
    }
    return (
        <OverlayTrigger placement="bottom-end" overlay={<Tooltip>{info}</Tooltip>}>
            {children}
        </OverlayTrigger>
    )
}

export default InfoTip;