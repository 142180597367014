import React from 'react'
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const ShowOverrideWeight = ({status, overrideWeight}) => {
    switch (status) {
        case 'Pending':
            return (
                <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="tooltip">Override Weight is Pending</Tooltip>}>
                    <span className='pending-text'>{overrideWeight > 0 ? `${overrideWeight}%` : '-'}</span>
                </OverlayTrigger>
            )
        case 'Approved':
            return (
                <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="tooltip">Override Weight is Approved</Tooltip>}>
                    <span className='approved-text'>{overrideWeight > 0 ? `${overrideWeight}%` : '-'}</span>
                </OverlayTrigger>
            )
        case 'Rejected':
            return (
                <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="tooltip">Override Weight is Rejected </Tooltip>}>
                    <span className='rejected-text'>{overrideWeight > 0 ? `${overrideWeight}%` : '-'}</span>
                </OverlayTrigger>
            )
        default:
            return <span className='default-text'>{overrideWeight > 0 ? `${overrideWeight}%` : '-'}</span>
    }
}

export default ShowOverrideWeight
