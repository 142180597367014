import ForbiddenPage from "../ForbiddenPage/ForbiddenPage";

const ProtectedRoute = ({ component: Component, ...restOfProps }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const isAuthenticatedRole =
    user && (user.role === "super-admin" || user.role === "compliance-admin");

  if (!isAuthenticatedRole) {
    return <ForbiddenPage />;
  }

  // Return the protected component if authenticated and authorized
  return <Component {...restOfProps} />;
};

export default ProtectedRoute;
