import React from 'react'

const ShowStatus = ({ status }) => {
    let statusClass = '';
    let displayText = status;

    switch (status) {
        case 'Pending':
            statusClass = 'pending-text';
            break;
        case 'Approved':
            statusClass = 'approved-text';
            break;
        case 'Rejected':
            statusClass = 'rejected-text';
            break;
        default:
            statusClass = 'default-text';
            displayText = '-';
    }

    return (
        <span className={statusClass}>{displayText}</span>
    )
}

export default ShowStatus
