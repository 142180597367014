import React from 'react'
import { Modal } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify'
import PageData from '../../utils/PageData.json'; // Import the JSON file


const InforModal = ({ showModal, handleCloseModal, path }) => {
  const resultObj = PageData.pages.find(page => page.pageName === path);
  return (
    <>
            <Modal show={showModal} onHide={handleCloseModal}   centered>
                <Modal.Header closeButton>
                    <Modal.Title>{resultObj.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{resultObj.description}</p>
                </Modal.Body>
            </Modal>
            <ToastContainer />
        </>
  )
}

export default InforModal