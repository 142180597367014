import React from 'react'
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const ShowWeight = ({status, overrideWeight, weight}) => {
    let displayText = overrideWeight > 0 ? overrideWeight : weight;

    switch (status) {
        case 'Pending':
            return (
                <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="tooltip">Override Weight is Pending</Tooltip>}>
                    <span className='pending-text'>{displayText}%</span>
                </OverlayTrigger>
            )
        case 'Approved':
            return (
                <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="tooltip">Override Weight is Approved</Tooltip>}>
                    <span className='approved-text'>{displayText}%</span>
                </OverlayTrigger>
            )
        default:
            return <span className='default-text'>{weight}%</span>
    }
}

export default ShowWeight
