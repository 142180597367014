export const consumerBankingStatus = (apiData) => {
    const result = apiData.assessment_units?.some((assessmentUnit) =>
      assessmentUnit.risk_categories?.some(
        (riskCategory) =>
          [
            "Customer Risk",
            "Geography Risk",
            "Product Risk",
            "Channel Risk",
            "TF/PF Risk",
            "Fraud Risk",
          ].includes(riskCategory.name) &&
          (
            riskCategory.key_risk_indicators?.some((keyRiskIndicator) =>
              ["Pending", "Approved"].includes(keyRiskIndicator.status)
            ) ||
            riskCategory.sub_categories?.some((subCat) =>
              ["Pending", "Approved"].includes(subCat.status)
            )
          )
      )
    );
  
    if (result) {
      // Find the latest updated date among key risk indicators and sub-categories
      const latestUpdate = apiData.assessment_units.reduce((latest, unit) => {
        const unitLatestUpdate = unit.risk_categories.reduce(
          (latestUpdate, category) => {
            const categoryKeyRiskUpdate = category.key_risk_indicators.reduce(
              (latestUpdate, indicator) =>
                indicator.updated_at > latestUpdate
                  ? indicator.updated_at
                  : latestUpdate,
              latestUpdate
            );
  
            const categorySubCatUpdate = category.sub_categories?.reduce(
              (latestUpdate, subCat) =>
                subCat.updated_at > latestUpdate ? subCat.updated_at : latestUpdate,
              latestUpdate
            );
  
            const categoryLatestUpdate = categoryKeyRiskUpdate > categorySubCatUpdate
              ? categoryKeyRiskUpdate
              : categorySubCatUpdate;
  
            return categoryLatestUpdate > latestUpdate
              ? categoryLatestUpdate
              : latestUpdate;
          },
          latest
        );
  
        return unitLatestUpdate > latest ? unitLatestUpdate : latest;
      }, "");
  
      return {
        hasRisk: true,
        latestUpdate: latestUpdate,
      };
    }
  
    return {
      hasRisk: false,
      latestUpdate: null,
    };
  };
  


export const complianceUserStatus = (apiData) => {
    const result = apiData.assessment_units?.some((assessmentUnit) =>
      assessmentUnit.risk_categories?.some(
        (riskCategory) =>
          [
            "Customer Risk",
            "Geography Risk",
            "Product Risk",
            "Channel Risk",
            "TF/PF Risk",
            "Fraud Risk",
          ].includes(riskCategory.name) &&
          (
            riskCategory.key_risk_indicators?.some((keyRiskIndicator) =>
              ["Approved", "Rejected"].includes(keyRiskIndicator.status)
            ) ||
            riskCategory.sub_categories?.some((subCat) =>
              ["Done", "Rejected"].includes(subCat.status)
            )
          )
      )
    );
  
    if (result) {
      // Find the latest updated date among key risk indicators and sub-categories
      const latestUpdate = apiData.assessment_units.reduce((latest, unit) => {
        const unitLatestUpdate = unit.risk_categories.reduce(
          (latestUpdate, category) => {
            const categoryKeyRiskUpdate = category.key_risk_indicators.reduce(
              (latestUpdate, indicator) =>
                indicator.updated_at > latestUpdate
                  ? indicator.updated_at
                  : latestUpdate,
              latestUpdate
            );
  
            const categorySubCatUpdate = category.sub_categories?.reduce(
              (latestUpdate, subCat) =>
                subCat.updated_at > latestUpdate ? subCat.updated_at : latestUpdate,
              latestUpdate
            );
  
            const categoryLatestUpdate = categoryKeyRiskUpdate > categorySubCatUpdate
              ? categoryKeyRiskUpdate
              : categorySubCatUpdate;
  
            return categoryLatestUpdate > latestUpdate
              ? categoryLatestUpdate
              : latestUpdate;
          },
          latest
        );
  
        return unitLatestUpdate > latest ? unitLatestUpdate : latest;
      }, "");
  
      return {
        hasRisk: true,
        latestUpdate: latestUpdate,
      };
    }
  
    return {
      hasRisk: false,
      latestUpdate: null,
    };
};
