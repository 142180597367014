import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { TreemapController, TreemapElement } from "chartjs-chart-treemap";
import React from "react";
import { Chart } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TreemapController,
  TreemapElement
);

const TreemapChart = ({ data, isLoading }) => {

  if (isLoading) {
    // Shuffle the data array for testing purposes
    data = data.slice().sort(() => Math.random() - 0.5);
  }
  // console.log(data);
  const options = {
    plugins: {
       title: {
         display: false,
         text: "Treemap",
       },
       legend: {
         display: false,
       },
       tooltip: {
         displayColors: false,
         callbacks: {
           title(items) {
             // Get the title text
             const titleText = items[0].raw._data.name;
             // Check if the title text is longer than 40 characters
             if (titleText.length > 40) {
               // Split the title text into two parts at the 40th character
               let firstPart = titleText.substring(0, 40);
               let secondPart = titleText.substring(40);
   
               // Check if the split is in the middle of a word
               if (firstPart.charAt(firstPart.length - 1).match(/\w/)) {
                 // Find the last space before the split point
                 const lastSpaceIndex = firstPart.lastIndexOf(' ');
                 if (lastSpaceIndex > -1) {
                   // Split at the last space before the 40th character
                   firstPart = titleText.substring(0, lastSpaceIndex);
                   secondPart = titleText.substring(lastSpaceIndex + 1);
                 }
               }
   
               // Add a hyphen to the end of the first part if it's not a complete word
               if (secondPart.charAt(0).match(/\w/)) {
                 firstPart += '-';
               }
   
               // Return the two parts as an array to create a line break in the tooltip
               return [firstPart, secondPart];
             }
             // If the title text is not too long, return it as is
             return titleText;
           },
           label(item) {
             const {
               _data: { percent },
             } = item.raw;
             return [`Risk: ${percent}%`];
           },
         },
         titleFont: {
           size: 12,
         },
       },
    },
    maintainAspectRatio: false,
    responsive: true,
    aspectRatio: 1,
   };
   
   
   

  const config = {
    type: "treemap",
    data: {
      datasets: [
        {
          tree: data,
          key: "percent",
          labels: {
            display: true,
            color: "white",
            formatter: (context) => {
              // const { dataset } = context;
              // const data = dataset.tree;

              // Sort the data by 'percent' property in descending order
              // const sortedData = data.slice().sort((a, b) => b.percent - a.percent);

              // Check if the current item is among the top 3
              // const isTop3 = sortedData.indexOf(context.raw._data) < 3;

              const labels = [];
             // if (isTop3) {
              //   // Limit the label text to a certain number of words, e.g., 5 words
              //   const words = context.raw._data.name.split(' ');
              //   labels.push(words);
              //   labels.push('');
              // }          

              labels.push(`${context.raw._data.percent}%`);
              return labels;
            },
          },
          backgroundColor(context) {
            if (context.type !== "data") return "transparent";
            const { color } = context.raw._data;
            return color;
          },
        },
      ],
    },
  };

  return <Chart type="treemap" data={config.data} options={options} />;
};

export default TreemapChart;
