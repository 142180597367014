import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import bottomAarow from "../../assets/image/bottom-aarow.svg";
import Dot from "../../assets/image/dot.svg";
import InformationIcon, {
  default as infoIcon,
} from "../../assets/image/i-icon.svg";
import { ControlRatingOptions } from "../../constants/ControlRatingOptions";
import ChangeRatingModal from "../../pages/ChangeRatingModal/ChangeRatingModal";
import DownloadAsPDF from "../../pages/DownloadAsPDF";
import EmailPopup from "../../pages/Email/EmailPopup";
import InforModal from "../../pages/InformationModal/InfoModal";
import ShowStatus from "../../pages/ShowStatus";
import { getActiveAssessmentData } from "../../services/ApiService";
import { DateFormat } from "../../utils/customHooks/DateFormat";
import { showNotification } from "../../utils/customHooks/Toast";
import { useAuth } from "../Auth/AuthContext";
import { AssessmentLogs } from "../Breadcrumb/GetLogs";
import Layout from "../Common/Layout";
import "./ControlRisk.css";
const ControlRisk = () => {
  // Ref for a DOM element
  const contentRef = useRef(null);

  // States for managing selected options
  const [selectedCategory, setCategory] = useState(0);
  const [selectedSubCategory, setSubCategory] = useState(0);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showInformationModal, setShowInformationModal] = useState(false);
  const [selectedKRI, setKRI] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  // Functions for showing the modal
  const handleShowModal = (kriData) => {
    setShowModal(true);
    setKRI(kriData);
  };

  // Functions for hiding the modal
  const handleCloseModal = () => setShowModal(false);

  const handleShowInformationModal = () => {
    setShowInformationModal(true);
  };

  const handleCloseInformationModal = () => setShowInformationModal(false);

  const { user } = useAuth();


  useEffect(() => {
    setIsLoading(true); // Set loading state to true
    // Fetch configuration data when the component mounts
    getActiveAssessmentData()
      .then((response) => {
        const data = response.data.find((item) => item.name === "Control Risk");
        setData(data);
        setIsLoading(false); // Set loading state to false when data is loaded
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false); // Set loading state to false when data is loaded
        showNotification("Error from server fetching data", "error");
      });
  }, [showModal]);

  const tooltipContent = (
    <Tooltip id="tooltip">Control risk assessment is still pending</Tooltip>
  );

  return (
    <Layout>
      <div className="total-overview" ref={contentRef}>
        <div className="top-heading">
          <h2 className="heading-common exclude-from-pdf">
            Control Risk
            
            <OverlayTrigger placement="right" overlay={tooltipContent}>
            <small className="fa fa-exclamation-triangle mx-2"></small>
            </OverlayTrigger>
          </h2>
          <div className="top-icons-heading">
            <img
              src={infoIcon}
              className="exclude-from-pdf"
              alt="icon"
              onClick={handleShowInformationModal}
              style={{ cursor: "pointer" }}
            />
            <DownloadAsPDF contentRef={contentRef} />
            <EmailPopup contentRef={contentRef} />
          </div>
        </div>

        {/* breadcrumb */}
        <div className="col-12 exclude-from-pdf">
          <AssessmentLogs data={data} />
        </div>

        {/* Toggle Menu */}
        <ul className="nav nav-tabs common-tab" id="myTab" role="tablist">
          {data?.assessment_units && data.assessment_units.length > 0
            ? data.assessment_units[0]?.risk_categories?.map(
                (category, tabIndex) => (
                  <li key={tabIndex}>
                    <button
                      className={`nav-link ${
                        tabIndex === selectedCategory ? "active" : ""
                      }`}
                      id={`tab-${tabIndex}`}
                      onClick={() => setCategory(tabIndex)}
                      type="button"
                      role="tab"
                      aria-controls={`tab-pane-${tabIndex}`}
                      aria-selected={tabIndex === selectedCategory}
                    >
                      {category.name}
                    </button>
                  </li>
                )
              )
            : null}
        </ul>

        {/* Div that will display data on Toggle Menu */}
        {isLoading ? (
          <div className="spinner-container center-spinner">
            <div className="spinner">
              <div className="spinner-border text-secondary" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          </div>
        ) : (
          <div className="tab-content" id="myTabContent">
            {data?.assessment_units && data.assessment_units.length > 0
              ? data.assessment_units[0]?.risk_categories?.map(
                  (category, tabIndex) => (
                    <div
                      key={`tab-pane-${tabIndex}`}
                      className={`tab-pane accordion-item ${
                        tabIndex === selectedCategory ? "show active" : ""
                      }`}
                      id={`tab-pane-${tabIndex}`}
                      role="tabpanel"
                      aria-labelledby={`tab-${tabIndex}`}
                    >
                      <div
                        id={`collapse-${tabIndex}`}
                        className={`accordion-collapse collapse ${
                          tabIndex === selectedCategory
                            ? "show d-lg-block"
                            : "d-lg-block"
                        }`}
                        aria-labelledby={`heading-${tabIndex}`}
                        data-bs-parent="#myTabContent"
                      >
                        <div className="accordion-body">
                          <table className="table configuration-table table-half-bordered">
                            <tbody>
                              {category.sub_categories?.map(
                                (subCategory, index) => (
                                  <React.Fragment key={subCategory.id}>
                                    <tr
                                      className={
                                        "submenu-item th-heading border-left border-right"
                                      }
                                    >
                                      <td width="40%">{subCategory.name}</td>
                                      <td>
                                        {selectedSubCategory === subCategory.id
                                          ? "Weight"
                                          : `${subCategory.weight}%`}
                                      </td>
                                      <td>
                                        {selectedSubCategory === subCategory.id
                                          ? "Control Risk"
                                          : null}
                                      </td>
                                      <td>
                                        {selectedSubCategory ===
                                        subCategory.id ? (
                                          "Status"
                                        ) : (
                                          <React.Fragment>
                                            Status
                                            {subCategory.status ===
                                            "Pending" ? (
                                              <i className="fa fa-exclamation-triangle mx-2"></i>
                                            ) : subCategory.status ===
                                              "Done" ? (
                                              <i className="fa fa-check-circle text-success mx-2"></i>
                                            ) : null}
                                          </React.Fragment>
                                        )}
                                      </td>
                                      <td>
                                        <small>
                                          Updated Date{" "}
                                          {subCategory.updated_at !== null
                                            ? " : " +
                                              DateFormat(subCategory.updated_at)
                                            : ""}
                                        </small>
                                      </td>
                                      <td>
                                        <Button
                                          className={
                                            "no-btn " +
                                            (selectedSubCategory ===
                                            subCategory.id
                                              ? "arrow-move"
                                              : "")
                                          }
                                          onClick={() =>
                                            setSubCategory(
                                              selectedSubCategory ===
                                                subCategory.id
                                                ? 0
                                                : subCategory.id
                                            )
                                          }
                                          variant="text"
                                        >
                                          <img src={bottomAarow} alt="icon" />
                                        </Button>
                                      </td>
                                    </tr>

                                    {subCategory.key_risk_indicators?.map(
                                      (kri) => (
                                        <tr
                                          className={
                                            "border-left border-right collapse accordion-collapse " +
                                            (selectedSubCategory ===
                                            subCategory.id
                                              ? "show"
                                              : "")
                                          }
                                          key={kri.id}
                                        >
                                          <td>{kri.name}</td>
                                          <td>{kri.weight}%</td>
                                          <td>
                                            {kri.risk === "Ineffective"
                                              ? kri.overrideRisk
                                                ? kri.overrideRisk
                                                : kri.risk
                                              : kri.risk}
                                          </td>

                                          <td>
                                            <ShowStatus status={kri.status} />
                                          </td>
                                          <td>
                                            {kri.updated_at !== null
                                              ? DateFormat(kri.updated_at)
                                              : "-"}
                                          </td>
                                          <td>
                                            <Button
                                              className={"no-btn"}
                                              variant="text"
                                              
                                              onClick={() => {
                                          if (
                                            user.role === "business-user" &&
                                            (kri.status === "Approved" ||
                                              kri.status === "Rejected") &&
                                            kri.status !== "Pending"
                                          ) {
                                            showNotification("kri already reviewd by compliance", "error");
                                          } else {
                                            // Open the modal
                                            handleShowModal(kri);
                                          }
                                        }}
                                            >
                                              <img
                                                src={Dot}
                                                alt="Dot"
                                                className="right-move-icon"
                                              />
                                            </Button>
                                          </td>
                                        </tr>
                                      )
                                    )}

                                    <tr className={"submenu-item th-heading"}>
                                      <td
                                        colSpan={5}
                                        style={{ background: "#fff" }}
                                      ></td>
                                    </tr>
                                  </React.Fragment>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )
                )
              : null}
          </div>
        )}
      </div>

      {/* Modal for Updating */}
      <ChangeRatingModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        selectedKRI={selectedKRI}
        ratingLabel={"Control Risk"}
        ratingOptions={ControlRatingOptions}
      />
      <InforModal
        showModal={showInformationModal}
        handleCloseModal={handleCloseInformationModal}
        path="control-risk"
      />
    </Layout>
  );
};

export default ControlRisk;
