export const isAllApproved = (data) => {
    let isAllApproved = true; // Initialize a flag variable as true

    data.forEach((riskType) => {
        riskType.assessment_units.forEach((unit) => {
            if (unit.status === 'Pending') {
                isAllApproved = false; // Set the flag to false if 'Pending' status is encountered
            }

            unit.risk_categories.forEach((category) => {
                if (category.status === 'Pending') {
                    isAllApproved = false;
                }

                category.sub_categories.forEach((sc) => {
                    if (sc.status === 'Pending') {
                        isAllApproved = false;
                    }

                    sc.key_risk_indicators.forEach((kri) => {
                        if (kri.status === 'Pending') {
                            isAllApproved = false;
                        }
                    });
                });

                category.key_risk_indicators.forEach((kri) => {
                    if (kri.status === 'Pending') {
                        isAllApproved = false;
                    }
                });
            });
        });
    });

    return isAllApproved; // Return the flag variable
};
