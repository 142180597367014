import { useState } from "react";
import { Modal } from "react-bootstrap";

export default function CalculateWeightModal({
  showCalcWeightModal,
  setShowCalcWeigthModal,
}) {
  return (
    <Modal
      show={showCalcWeightModal}
      onHide={() => setShowCalcWeigthModal(false)}
      centered
    >
      <Modal.Body>
        <h5>Weight Calculation Done Successfully</h5>
      </Modal.Body>
    </Modal>
  );
}
