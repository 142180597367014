export const getColorClass = (risk) => {
    if (risk === 'L' || risk === 'Low') {
        return 'text-success';
    } else if (risk === 'M' || risk === 'Medium') {
        return 'text-warning';
    } else if (risk === 'H' || risk === 'High') {
        return 'text-danger';
    } else {
        return 'text-secondary';
    }
}