import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Dot from "../../assets/image/dot.svg";
import { default as infoIcon } from "../../assets/image/i-icon.svg";
import { InherentRatingOptions } from "../../constants/InherentRisks";
import ChangeRatingModal from "../../pages/ChangeRatingModal/ChangeRatingModal";
import DownloadAsPDF from "../../pages/DownloadAsPDF";
import EmailPopup from "../../pages/Email/EmailPopup";
import InforModal from "../../pages/InformationModal/InfoModal";
import ShowStatus from "../../pages/ShowStatus";
import { getActiveAssessmentData } from "../../services/ApiService";
import { DateFormat } from "../../utils/customHooks/DateFormat";
import { getColorClass } from "../../utils/customHooks/GetColorClass";
import { showNotification } from "../../utils/customHooks/Toast";
import { useAuth } from "../Auth/AuthContext";
import { AssessmentLogs } from "../Breadcrumb/GetLogs";
import Layout from "../Common/Layout";
import "./InherentRisk.css";

const InherentRisk = () => {
  // Ref for a DOM element
  const contentRef = useRef(null);
  // States for managing selected options
  const [selectedCategory, setCategory] = useState(0);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showInformationModal, setShowInformationModal] = useState(false);
  const [selectedKRI, setKRI] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { user } = useAuth();

  // Functions for showing the modal
  const handleShowModal = (kriData, category_id) => {
    setShowModal(true);
    setKRI({
      ...kriData,
      category_id: category_id,
    });
  };


  // Functions for hiding the modal
  const handleCloseModal = () => setShowModal(false);

  const handleShowInformationModal = () => {
    setShowInformationModal(true);
  };

  const handleCloseInformationModal = () => setShowInformationModal(false);

  const fetchData = () => {
    setIsLoading(true); // Set loading state to true
    // Fetch Active Assessment data when the component mounts
    getActiveAssessmentData()
      .then((response) => {
        const data = response.data.find(
          (item) => item.name === "Inherent Risk"
        );
        console.log(data)
        setData(data);
        setIsLoading(false); // Set loading state to false when data is loaded
      })
      .catch((error) => {
        // console.log(error)
        setIsLoading(false); // Set loading state to false when data is loaded
        showNotification("Error from server fetching data", "error");
      });
  };

  useEffect(() => {
    fetchData();
  }, [showModal]);

  const tooltipContent = (
    <Tooltip id="tooltip">Inherent risk assessment is still pending</Tooltip>
  );

  return (
    <Layout>
      <div className="total-overview" ref={contentRef}>
        <div className="top-heading">
          <h2 className="heading-common exclude-from-pdf">
            Inherent Risk
            <OverlayTrigger placement="right" overlay={tooltipContent}>
              <small className="fa fa-exclamation-triangle mx-2"></small>
            </OverlayTrigger>
          </h2>
          <div className="top-icons-heading">
            <img
              src={infoIcon}
              className="exclude-from-pdf"
              alt="icon"
              onClick={handleShowInformationModal}
              style={{ cursor: "pointer" }}
            />
            <DownloadAsPDF contentRef={contentRef} />
            <EmailPopup contentRef={contentRef} />
          </div>
        </div>

        {/* breadcrumb */}
        <div className="col-12 exclude-from-pdf">
          <AssessmentLogs data={data} />
        </div>

        {/* Toggle Menu */}
        <ul className="nav nav-tabs common-tab" id="myTab" role="tablist">
          {data?.assessment_units && data.assessment_units.length > 0
            ? data.assessment_units[0]?.risk_categories?.map(
                (category, index) => (
                  <li key={index}>
                    <button
                      className={`nav-link ${
                        index === selectedCategory ? "active" : ""
                      }`}
                      id={`tab-${index}`}
                      onClick={() => setCategory(index)}
                      type="button"
                      role="tab"
                      aria-controls={`tab-pane-${index}`}
                      aria-selected={index === selectedCategory}
                    >
                      {category.name}
                    </button>
                  </li>
                )
              )
            : null}
        </ul>

        {/* Div that will display data on Toggle Menu */}
        {isLoading ? (
          <div className="spinner-container center-spinner">
            <div className="spinner">
              <div className="spinner-border text-secondary" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          </div>
        ) : (
          <div className="tab-content" id="myTabContent">
            {data?.assessment_units && data.assessment_units.length > 0
              ? data.assessment_units[0]?.risk_categories?.map(
                  (category, index) => (
                    <div
                      key={`tab-pane-${index}`}
                      className={`tab-pane accordion-item ${
                        index === selectedCategory ? "show active" : ""
                      }`}
                      id={`tab-pane-${index}`}
                      role="tabpanel"
                      aria-labelledby={`tab-${index}`}
                    >
                      <div
                        id={`collapse-${index}`}
                        className={`accordion-collapse collapse ${
                          index === selectedCategory
                            ? "show d-lg-block"
                            : "d-lg-block"
                        }`}
                        aria-labelledby={`heading-${index}`}
                        data-bs-parent="#myTabContent"
                      >
                        <div className="accordion-body">
                          <table className="table configuration-table table-half-bordered">
                            <thead>
                              <tr>
                                <th scope="col" width="40%">
                                  Category
                                </th>
                                <th scope="col" width="8%">
                                  Values
                                </th>
                                <th scope="col" width="8%">
                                  Weights
                                </th>
                                <th scope="col" width="8%">
                                  Rating
                                </th>
                                <th scope="col" width="11%">
                                  Revised Rating
                                </th>
                                <th scope="col" width="8%">
                                  Status
                                </th>
                                <th scope="col" width="11%">
                                  Updated Date
                                </th>
                                <th scope="col" width="7%"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {category.key_risk_indicators?.map((kri) => (
                                <React.Fragment key={kri.id}>
                                  <tr>
                                    <td>{kri.name}</td>
                                    <td>{kri.value}</td>
                                    <td>{kri.weight}%</td>
                                    <td>
                                      <i
                                        className={`fa fa-square ${getColorClass(
                                          kri.risk
                                        )}`}
                                      ></i>
                                    </td>
                                    <td>
                                      <i
                                        className={`fa fa-square ${getColorClass(
                                          kri.overrideRisk
                                        )}`}
                                      ></i>
                                    </td>
                                    <td>
                                      {" "}
                                      <ShowStatus status={kri.status} />{" "}
                                    </td>
                                    <td>{DateFormat(kri.updated_at)}</td>
                                    <td>
                                      <Button
                                        className={"no-btn"}
                                        variant="text"
                                        onClick={() => {
                                          if (
                                            user.role === "business-user" &&
                                            (kri.status === "Approved")
                                               &&
                                            kri.status !== "Pending"
                                          ) {
                                            showNotification("kri already reviewd by compliance", "error");
                                          } else {
                                            // Open the modal
                                            handleShowModal(kri, category.id);
                                          }
                                        }}
                                      >
                                        <img
                                          src={Dot}
                                          alt="Dot"
                                          className="right-move-icon"
                                        />
                                      </Button>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )
                )
              : null}
          </div>
        )}
      </div>

      {/* Modal for Updating */}
      <ChangeRatingModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        selectedKRI={selectedKRI}
        ratingLabel={"Override Rating:"}
        ratingOptions={InherentRatingOptions}
      />
      <InforModal
        showModal={showInformationModal}
        handleCloseModal={handleCloseInformationModal}
        path="inherent-risk"
      />
    </Layout>
  );
};

export default InherentRisk;
