import React, { createContext, useContext, useState } from 'react';

export const AssessmentUnitContext = createContext();

export const AssessmentUnitProvider = ({ children }) => {
  const [selectedUnit, setSelectedUnit] = useState("All");

  const updateSelectedUnit = (unit) => {
    setSelectedUnit(unit);
  };

  return (
    <AssessmentUnitContext.Provider value={{ selectedUnit, updateSelectedUnit }}>
      {children}
    </AssessmentUnitContext.Provider>
  );
};

// Create a custom hook for using the auth context
export const useAssessmentunit = () => {
  return useContext(AssessmentUnitContext);
};